html {
  box-sizing: border-box;
  font-family: Arial, sans-serif;
  font-size: 16px;
}
body {
  margin: 0;
  font-size: 1.25rem;
  line-height: 1.2;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

a{
  color: #f08920;
}

p{
  margin: 0.5em 0 1em 0;
}

.app {
  margin: 50px 0;
}

.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 30px;
}

.generator {
  display: flex;
}

.preview {
  width: 300px;
}
.preview-container {
  color: black;
  background: white;
  padding: 30px;
  margin-bottom: 30px;
  border-radius: 10px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  user-select: none;
}
.preview-content {
  font-family: Arial;
  font-size: 13px;
  pointer-events: none;
}
.color-mode {
  background: white;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  position: absolute;
  right: -20px;
  top: -20px;
  cursor: pointer;
}
.color-mode svg {
  width: 24px;
  fill: black;
}

.preview-container--dark {
  color: white;
  background: #1e1e1e;
}
.copy-signature {
  background: #fef2e6;
  padding: 20px 30px;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  line-height: 1;
  border: 1px solid #f08920;
}
.copy-signature--copied {
  background: #47cf73;
  border: 1px solid #47cf73;
}

.inputs {
  flex: 1;
  margin-right: 50px;
}
.inputs .input-label {
  margin: 20px 0 5px 0;
}
.inputs input {
  width: 100%;
  font-size: 1rem;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.inputs input:focus-visible {
  outline: #f08920 auto 1px;
}
.inputs .dropdown {
  width: 100%;
  font-size: 1rem;
}

.inputs .dropdown__value-container {
  padding: 6px 8px;
}

.instructions h2{
  margin-top: 70px;
}
.instructions__content img{
  width: 100%;
}
.step{
  display: flex;
  margin-bottom: 30px;
}
.step .step__number{
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 2px solid #f08920;
  margin: 0 30px 30px 0;
}

.step .step__content{
  flex: 1;
}

.mail-clients{
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  margin-bottom: 50px;
}

.mail-clients__header{
  margin: 20px 0;
}
.mail-client{
  border: 1px solid #ccc;
  border-radius: 5px;
  flex: 1;
  cursor: pointer;
  text-align: center;
  user-select: none;
}
.mail-client--active{
  background: #fef2e6;
  border: 1px solid #f08920;
}
.mail-client__icon{
  padding: 20px 20px 10px 20px;
}
.mail-client p{
  margin: 0 0 20px 0;
}

@media (max-width: 500px) {
  .mail-client{
    width: calc(50% - 15px);
  }
}
@media (max-width: 350px) {
  .mail-clients{
    justify-content: center;
  }
  .mail-client{
    width: 100%;
    max-width: 200px;
  }
}
@media (max-width: 700px) {
  .generator {
    flex-direction: column;
  }
  .preview {
    width: 100%;
  }
  .inputs {
    margin-right: 0px;
  }
  .app {
    margin: 50px 0;
  }
  .preview h2{
    margin-top: 50px;
  }
  .instructions h2{
    margin-top: 50px;
  }
  .step{
    flex-direction: column;
  }
}
@media (max-width: 800px) {
  .mail-client{
    flex: 0 1 auto;
    width: 124px;
  }
}
